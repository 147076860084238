import axios from 'axios'
import router from '../router';
import { getStorage, setStorage } from './session'
import { Message } from 'element-ui';
const second = 1000

axios.defaults.timeout = second * 30


//请求拦截器
axios.interceptors.request.use(
    config => {
        const Authorization = `${getStorage(
            'access_token'
        )}`
        config.headers = { Authorization: Authorization }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)


//响应拦截器
axios.interceptors.response.use(response => {
    // token过期
    if (response.data.code === 4001) {
        if (getStorage('access_token')) {
            Message({
                type: 'error',
                message: '登录状态失效',
                duration: 3000
            })
        }
        setStorage('access_token', '')
        router.push('/login')
        return
    }
    // 账户不存在
    if (response.data.code === 4002) {
        Message({
            type: 'error',
            message: '账户不存在或Token错误',
            duration: 3000
        })
        router.push('/login')
        return
    }

    // 账号禁用
    if (response.data.code === 5102) {
        Message({
            type: 'error',
            message: '账号被禁用,请联系管理员',
            duration: 3000
        })
        router.push('/login')
        return
    }
    // 没有权限
    if (response.data.code === 5100) {
        Message({
            type: 'error',
            message: '无权访问',
            duration: 3000
        })
        return
    }
    // 系统升级中
    if (response.data.code === 5103) {
        Message({
            type: 'error',
            message: '系统升级中',
            duration: 3000
        })
        router.push('/Upgrading')
    }

    if (response.headers.filename) {
        return {
            filename: window.decodeURI(response.headers.filename) + '.xls',
            data: response.data
        }
    } else {
        if (response.data.code !== 1000 && response.data.code !== 4001 && response.data.code !== 5102 && response.data.code !== 5103 && response.data.code !== 5104 && router.history.current.name != "appLogin" && router.history.current.name != "applicRegis") {
            Message({
                type: 'error',
                message: response.data.showMsg,
                duration: 3000
            })
        } else {
            return response.data
        }
    }
}, (error) => {
    console.log(error);
})




export default axios
