import { api_auth } from '@/utils/api'
import axios from '@/utils/axios'
const roles = {
    // namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        /* 获取用户列表 */
        async getAuthUserList({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.getUserList, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取用户详情 */
        async getUserGroupDetail({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.getUserGroupDetail, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 更新用户分组详情 */
        async updateUserGroupDetail({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.updateUserGroupDetail, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 重置用户密码 */
        async resetUserPassword({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.resetUserPassword, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 删除用户分组 */
        async delGroup({ commit }, id) {
            try {
                commit
                const res = await axios.get(`${api_auth.delGroup}?id=${id}`)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取分组列表 */
        async getGroup({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.getGroup, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 添加分组 */
        async addGroup({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.addGroup, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取分组-用户 */
        async getGroupUser({ commit }, gid) {
            try {
                commit
                const res = await axios.get(`${api_auth.getGroupUser}?gid=${gid}`)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取分组-权限 */
        async getGroupRules({ commit }, gid) {
            try {
                commit
                const res = await axios.get(`${api_auth.getGroupRules}?gid=${gid}`)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 更新分组 */
        async updateGroup({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.updateGroup, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 更新用户-分组 */
        async updateGroupUser({ commit }, formData) {
            try {
                commit
                const res = await axios.post(api_auth.updateGroupUser, formData)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取角色搜索建议 */
        async getGroupSuggestion({ commit }, name) {
            try {
                commit
                const res = await axios.post(`${api_auth.getGroupSuggestion}?name=${name}`)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
    }
}

export default roles
