<template>
  <div id="app">
    <router-view v-if="isShow" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isShow: true,
    };
  },
  methods: {
    reload(){
      this.isShow=false;
      this.$nextTick(()=>{
        this.isShow=true
      })
    }
  },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: overlay;
}

#app {
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
}
</style>
