import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter);


const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/Layout'),
        redirect: 'CreateProduct',
        children: [
            {
                path: 'CurveList',
                component: () => import('@/components/Curve/List'),
                meta: {
                    keepAlive: true,
                }
            },
            {
                path: 'CurveDetail',
                component: () => import('@/components/Curve/Detail'),
            },
            {
                path: 'UploadFile',
                component: () => import('@/components/Curve/UploadFile'),
            },
            {
                path: 'CreateProduct',
                component: () => import('@/components/ProductCenter/ProductCreate'),
            },
            {
                path: 'ProductList',
                component: () => import('@/components/ProductCenter/ProductList'),
                meta: {
                    keepAlive: true,
                }
            },
            {
                path: 'ProductDetail',
                name: 'ProductDetail',
                component: () => import('@/components/ProductCenter/ProductDetail'),
            },
            {
                path: 'ProductCheck',
                name: 'ProductCheck',
                component: () => import('@/components/ProductCenter/ProductCheck'),
            },
            {
                path: 'ProductPack',
                name: 'ProductPack',
                component: () => import('@/components/ProductCenter/ProductPack'),
            },
            {
                path: 'ProductRepair',
                name: 'ProductRepair',
                component: () => import('@/components/ProductCenter/ProductRepair'),
            },
            {
                path: 'ProductRelate',
                name: 'ProductRelate',
                component: () => import('@/components/ProductCenter/ProductRelate'),
            },
            {
                path: 'ProductCalculate',
                name: 'ProductCalculate',
                component: () => import('@/components/ProductCenter/ProductCalculate'),
            },
            {
                path: 'Account',
                component: () => import('@/components/ProductCenter/Account'),
            },

            //权限
            {
                path: '/indexRole',
                name: 'indexRole',
                component: () => import('@/components/role/IndexRole'),
                redirect: '/userManagement',
                meta: {
                    title: "权限管理",
                    icon: "",
                },
                menuShow: true,
                children: [
                    {
                        path: '/userManagement',
                        name: 'userManagement',
                        component: () => import('@/components/role/management/userManagement'),
                        meta: {
                            keepAlive: true
                        },
                        menuShow: true,
                    },
                    {
                        path: '/editUser',
                        name: 'editUser',
                        component: () => import('@/components/role/management/editUser'),
                        meta: {
                            title: "用户管理",
                        }
                    },
                    {
                        path: '/roleManagement',
                        name: 'roleManagement',
                        component: () => import('@/components/role/management/roleManagement'),
                        meta: {
                            title: "角色管理",
                        },
                        menuShow: true,
                    },
                    {
                        path: '/editRole',
                        name: 'editRole',
                        component: () => import('@/components/role/management/editRole'),
                        meta: {
                            title: "角色管理",
                        }
                    }
                ]
            },

            //物料管理
            {
                path: 'BomList',
                component: () => import('@/components/ProductCenter/BomList'),
            }
        ]
    },
    {
        path: '/Login',
        component: () => import('@/components/Login/Login'),
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/components/Test/test'),
    },

];



const router = new VueRouter({ mode: 'history', routes });

router.beforeEach((to, from, next) => {
    document.title = "研发生产管理系统";
    next()
});

// Vue 重复点击相同路由，出现 Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation 问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}




export default router
