import { api_wechat } from '@/utils/api'
import { removeStorage } from "../../utils/session";
import axios from '@/utils/axios'
const wechat = {
    // namespaced: true,
    state: {
        access_token:''
    },
    mutations: {
    },
    actions: {
        //获取微信用户列表
        async getUserList({ commit }) {
            try {
                const res = await axios.get(api_wechat.getUserList)
                if (res) {
                    commit
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },

        
       
    }
}

export default wechat