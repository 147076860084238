import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import curve from './modules/curve'
import wechat from './modules/wechat'
import pdCenter from './modules/pdCenter'
import roles from './modules/role'
import suggestion from './modules/suggestion'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        curve,
        wechat,
        pdCenter,
        roles,
        suggestion,
    }
})
