import { api_curve } from '@/utils/api'
import { removeStorage } from "../../utils/session";
import axios from '@/utils/axios'
const curve = {
    // namespaced: true,
    state: {
        access_token:''
    },
    mutations: {
        access_token(state, value) {
            state.access_token = value
        }
    },
    actions: {
        //获取曲线列表
        async getCurveList({commit}, params) {
            try {
                const res = await axios.post(api_curve.getCurveList, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },

         //获取曲线详情
         async getCurveDetail({ commit }, id) {
            try {
                const res = await axios.get(`${api_curve.getCurveDetail}?id=${id}`)
                if (res) {
                    commit
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },

        //删除曲线
        async delCurve({ commit }, id) {
            try {
                const res = await axios.get(`${api_curve.delCurve}?id=${id}`)
                if (res) {
                    commit
                    return Promise.resolve(res)
                }else{
                    return false;
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },

        //更新曲线
        async updateCurve({ commit }, params) {
            try {
                const res = await axios.post(api_curve.updateCurve, params)
                if (res) {
                    commit
                    return Promise.resolve(res)
                }else{
                    return false;
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },

    }
}

export default curve
