import router from "../router";

const storage = window.localStorage

if (!storage) {
    alert('浏览器不兼容，请使用Chrome或者支持Chrome内核的浏览器')
}

const isJsonStr = value => {
    const reg = /^\{|\[[\s\S]+\]\}$/
    return reg.test(value)
}

const isObj = value => value instanceof Object

const getStorage = key => {
    if (key) {
        const value = storage.getItem(key)
        if (isJsonStr(value)) {
            return JSON.parse(value)
        }
        return value
    }
    return false
}

const setStorage = (key, value) => {
    if (key) {
        if (isObj(value)) {
            storage.setItem(key, JSON.stringify(value))
        } else {
            storage.setItem(key, value)
        }
    }
    return false
}

const removeStorage = () => {
    storage.clear()
    router.replace({
        path: '/login'
    })
    return
}

export { setStorage, getStorage, removeStorage }
