import { api_pdCenter } from '@/utils/api'
import axios from '@/utils/axios'
const pdCenter = {
    actions: {
        //新增设备
        async addDevice({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.addDevice, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //删除设备
        async delDevice({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.delDevice, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //获取设备列表
        async getDeviceList({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getDeviceList, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //导出设备excel
        async exportDeviceList({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.exportDeviceList, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
         //修改设备详情
         async updateDeviceDetail({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.updateDeviceDetail, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
         //获取设备详情 by sn
         async getDeviceDetailBySn({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getDeviceDetailBySn, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
         //获取设备详情 by id
         async getDeviceDetailById({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getDeviceDetailById, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
         //打包设备
         async packDevice({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.packDevice, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //检查厂商名是否存在
        async checkFirmAccount({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.checkFirmAccount, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //获取厂商账户列表
        async getFirmAccountList({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getFirmAccountList, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //添加厂商账号
        async addFirmAccount({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.addFirmAccount, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //更新厂商账号
        async updateFirmAccount({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.updateFirmAccount, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //获取产品物料列表
        async getProductMaterialsList({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getProductMaterialsList, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //上传产品物料列表
        async updateProductMaterials({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.updateProductMaterials, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //获取统计数据
        async getStatisticsData({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.getStatisticsData, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //新增返修设备
        async addRepairDevice({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.addRepairDevice, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        //新增关联设备
        async addSnRelate({ commit }, params) {
            try {
                const res = await axios.post(api_pdCenter.addSnRelate, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
    }
}

export default pdCenter
