// 接口版本v1

const debug = true;
const isDev = process.env.NODE_ENV === 'development';
const host = isDev && debug ? 'http://www.iot' : 'https://iot.jsdrobot.com';


//登录
const api_login = {
    login: `${host}/api/v1/user/login`, //登录
    logout: `${host}/api/v1/user/logout`, //登出
    getUserMenu: `${host}/api/v1/user/getUserMenu`, //获取用户路由权限
};

//todo 权限
const api_auth = {
    getUserList: `${host}/api/v1/auth/getUserList`, //获取用户列表
    getUserGroupDetail: `${host}/api/v1/auth/getUserGroupDetail`, //获取用户分组详情
    updateUserGroupDetail: `${host}/api/v1/auth/updateUserGroupDetail`, //更新用户分组详情
    resetUserPassword: `${host}/api/v1/auth/resetUserPassword`, //重置用户密码
    delGroup: `${host}/api/v1/auth/delGroup`, //删除用户分组
    getGroup: `${host}/api/v1/auth/getGroup`, //获取分组列表
    addGroup: `${host}/api/v1/auth/addGroup`, //添加分组
    getGroupUser: `${host}/api/v1/auth/getGroupUser`, //获取分组-用户
    getGroupRules: `${host}/api/v1/auth/getGroupRules`, //获取分组-权限
    updateGroup: `${host}/api/v1/auth/updateGroup`, //更新分组
    updateGroupUser: `${host}/api/v1/auth/updateGroupUser`, //更新用户-分组
    getGroupSuggestion: `${host}/api/v1/auth/getGroupSuggestion`, //获取角色搜索建议
};

//曲线
const api_curve = {
    handleUploadData: `${host}/api/v1/curve/handleUploadData`, //上传数据
    getCurveList: `${host}/api/v1/curve/getCurveList`, //获取曲线列表
    getCurveDetail: `${host}/api/v1/curve/getCurveDetail`, //获取曲线详情
    updateCurve: `${host}/api/v1/curve/updateCurve`, //更新曲线详情
    delCurve: `${host}/api/v1/curve/delCurve`, //删除曲线
    getW11CommentList: `${host}/api/v1/curve/getW11CommentList`, //获取w11评论列表
    createW11Comment: `${host}/api/v1/curve/createW11Comment`, //增加w11评论列表

};

//微信
const api_wechat = {
    getUserList: `${host}/api/v1/wechat/getUserList`, //获取微信用户列表
};

//研发生产sn、mac
const api_pdCenter = {
    getDeviceList: `${host}/api/v1/pdCenter/getDeviceList`, //获取设备列表
    exportDeviceList: `${host}/api/v1/pdCenter/exportDeviceList`, //导出设备列表excel
    updateDeviceDetail: `${host}/api/v1/pdCenter/updateDevice`, //更新设备详情
    getDeviceDetailBySn: `${host}/api/v1/pdCenter/getDeviceDetailBySn`, //获取设备详情 by sn
    getDeviceDetailById: `${host}/api/v1/pdCenter/getDeviceDetailById`, //获取设备详情 by id
    packDevice: `${host}/api/v1/pdCenter/packDevice`, //打包设备
    addDevice: `${host}/api/v1/pdCenter/addDevice`, //新增设备
    delDevice: `${host}/api/v1/pdCenter/delDevice`, //删除设备
    checkFirmAccount: `${host}/api/v1/pdCenter/checkFirmAccount`, //检查厂商名是否存在
    getFirmAccountList: `${host}/api/v1/pdCenter/getFirmAccountList`, //获取厂商账户列表
    addFirmAccount: `${host}/api/v1/pdCenter/addFirmAccount`, //添加厂商账号
    updateFirmAccount: `${host}/api/v1/pdCenter/updateFirmAccount`, //更新厂商账号
    getProductMaterialsList: `${host}/api/v1/pdCenter/getProductMaterialsList`, //获取产品物料列表
    updateProductMaterials: `${host}/api/v1/pdCenter/updateProductMaterials`, //上传物料列表
    getStatisticsData: `${host}/api/v1/pdCenter/getStatisticsData`, //获取统计数据
    addRepairDevice: `${host}/api/v1/pdCenter/addRepairDevice`, //新增返修设备
    addSnRelate: `${host}/api/v1/pdCenter/addSnRelate`, //新增关联设备
};

//搜索建议
const api_suggestion = {
    getMaterialsSuggestion: `${host}/api/v1/suggestion/getMaterialsSuggestion`, //获取物料搜索建议
};

export {
    api_login,
    api_auth,
    api_curve,
    api_wechat,
    api_pdCenter,
    api_suggestion,

};
