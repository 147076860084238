import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import * as echarts from 'echarts'
import less from 'less'

import axios from 'axios'


import '@/assets/style/reset.css'
import moment from 'moment'

Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
moment.locale('zh-cn')

Vue.use(ElementUI);
Vue.use(less);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

