import { api_login } from '@/utils/api'
import { removeStorage } from "../../utils/session";
import axios from '@/utils/axios'
const user = {
    // namespaced: true,
    state: {
        access_token: ''
    },
    mutations: {
        access_token(state, value) {
            state.access_token = value
        }
    },
    actions: {
        /* 登录 */
        async passlogin({ commit }, { username = '', password = '' }) {
            try {
                const res = await axios.post(api_login.login, { username, password })
                if (res) {
                    commit('access_token', res.data.access_token)
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 登出 */
        async logout({ commit }) {
            try {
                commit('access_token', '')
                removeStorage()
                return Promise.resolve()
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
        /* 获取用户路由权限 */
        async getUserMenu({ commit }) {
            try {
                commit
                const res = await axios.post(api_login.getUserMenu)
                if (res) {
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        }
    }
}

export default user