import { api_suggestion } from '@/utils/api'
import axios from '@/utils/axios'
const suggestion = {
    actions: {
        //新增设备
        async getMaterialsSuggestion({ commit }, params) {
            try {
                const res = await axios.post(api_suggestion.getMaterialsSuggestion, params)
                if (res) {
                    commit;
                    return Promise.resolve(res)
                }
            } catch (err) {
                console.log(err)
                return Promise.reject(err)
            }
        },
    }
}

export default suggestion
